.app {
  display: flex;
  align-items: center;
  flex-direction: column;

  .description {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
    min-width: 250px;

    button {
      max-width: 50%;
      padding: 5px;
      margin: 15px;
    }
  }
}
